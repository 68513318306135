import ErrorLogger from '@/ErrorLogger';
import {useRouteError} from 'react-router';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import {observer} from 'mobx-react-lite';

const LivanErrorBoundary = observer(function LivanErrorBoundary() {
  const error = useRouteError() as Error;
  ErrorLogger.Log(
    error,
    {
      tags: {
        type: 'error-boundary',
      },
    },
    captureRemixErrorBoundaryError,
  );

  return <div>Oops! An error occurred. Please try again later</div>;
});

export default LivanErrorBoundary;
